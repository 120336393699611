/* global moment */

function isSIREN(siren = '', dev = false) {
    if (dev) {
        if (siren.length === 9 || siren.length === 14) {
            return true;
        } else {
            return false;
        }
    }
    return isValidSIREN(siren) || isValidSIRET(siren);
}

function isValidSIREN(siren, dev = false) {
    if (dev) {
        return true;
    }
    if ((siren.length !== 9) || (isNaN(siren))) {
        return false;
    } else {
        return luhnCheck(siren);
    }//
}
function isValidSIRET(siret) {
    if ((siret.length !== 14) || (isNaN(siret)))
        return false;
    else {
        return luhnCheck(siret);
    }
}

function luhnCheck(code) {
    const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
    let len = code.length, bit = 1, sum = 0, val;
    while (len) {
        val = parseInt(code.charAt(--len), 10);
        sum += (bit ^= 1) ? arr[val] : val;
    }
    return sum && sum % 10 === 0;
}

angular.module(config.app.name).component('person', {
    templateUrl: 'components/procedure/components/person.component.html',
    bindings: {
        procedureType: '<',
        instanceType: '<',
        person: '=',
        canGoBack: '<',
        setData: '&',
        getData: '&'
    },
    controller: function ($element, $scope, $timeout, $_procedures) {
        var self = this;

        self.view = {
            title: self.formTitle,
            icon: 'person'
        };

        self.deps = gData.departements;

        let maxBirthDate = new Date();
        maxBirthDate.setFullYear(maxBirthDate.getFullYear() - 14);
        self.minBirthDate = new Date(1900, 1, 1).toISOString().slice(0, 10);
        self.maxBirthDate = maxBirthDate.toISOString().slice(0, 10);

        self.formTitle = null;
        self.hidePersonType = false;

        self.currentStep = 0;

//------------------------------------------------------------------------------

        self.$onChanges = function (changes) {

            console.log('>>> changes :', 'info', 't::person');
            console.log(changes, 't::person');

        };

//------------------------------------------------------------------------------

        self.$postLink = function () {};

        self.listener = function ($event) {
            if ($event.type === 'component-load') {
                onLoad();
            }
        };

        function onLoad() {
            setTimeout(() => {
                $element[0].dispatchEvent(new CustomEvent('component-load'));
            });
        }

        angular.element(function () {
            onLoad();

            const stepper = document.getElementById('person-stepper');
            self.nbSteps = stepper.querySelectorAll('md-tab').length;

        });

        self.$onInit = function () {
            if (self.person) {

                self.person = __onDataIn();

                if (self.procedureType === DECLARATION_ACHAT && self.instanceType === 'buyer') {
                    self.hidePersonType = true;
                    self.person.type = 'personne-morale';
                }

                self.formTitle = self.person.formTitle;
                self.view.title = self.formTitle;

                $timeout(() => {
                    $scope.$apply();
                    self.personForm.$setSubmitted();
                });
            }

            /*self.getData({
             type: self.instanceType,
             cb: person => {
             self.person = person;
             self.formTitle = person.formTitle;
             $timeout(() => {
             $scope.$apply();
             self.personForm.$setSubmitted();
             });
             }
             });
             self.getData({
             type: ['procedureId', 'procedureType'],
             cb: data => {
             self.procedureId = data[0];
             self.procedureType = data[1];
             }
             });*/
        };

        function __onDataIn() {
            if (typeof self.person === 'string') {
                self.person = JSON.parse(self.person);
            }
            let data = Object.assign({}, self.person);
            if ('birthDate' in data && typeof data.birthDate === 'string') {
                let d = moment(data.birthDate, 'DD/MM/YYYY');
                data.birthDate = d.isValid() ? d.toDate() : null;
            }
            if (!('birthDate' in self.person)) {
                data.birthDate = null;
            }
            if (!('address' in self.person) && self.person.needAddress === true) {
                data.address = {};
            }
            return data;
        }

        function __onDataOut() {
            let data = Object.assign({}, self.person);
            for (let i in data) {
                if (data[i] instanceof Date || moment.isMoment(data[i])) {
                    data[i] = moment(data[i]).format('DD/MM/YYYY');
                }
            }
            self.cleanData(data);
            return data;
        }

        self.onPersonTypeChange = function () {
            console.log('>>> self.personType :', self.person.personType);
            console.log('>>> person :', self.person);
        };

        self.cleanData = function (personData) {
            if (personData.type === PERSONNE_PHYSIQUE) {
                delete personData.company;
                delete personData.siren;
            } else {
                delete personData.title;
                delete personData.lastname;
                delete personData.firstname;
                delete personData.marriedname;
                delete personData.birthDate;
                delete personData.birthPlace;
                delete personData.birthDepartment;
                delete personData.birthCountry;
            }
            if (self.person.needAddress !== true) {
                delete personData.address;
            }
        };

        self.validate = function () {
            let data = Object.assign({}, __onDataOut());
            //data.type = data.instanceType;
            /*if (data.birthDate) {
                data.birthDate = moment(data.birthDate).format('DD/MM/YYYY');
            }
            self.cleanData(data);
*/
            console.error('--------------------------------------------------');
            console.log('>>> send person data :', data);
            console.error('--------------------------------------------------');

            self.setData({
                type: self.instanceType,
                data: {person: data}
            });
        };

        self.onAddressSet = function (address, oldAddress = false) {
            let _address = Object.assign({}, address);
            if (_address !== null) {
                for (let i in _address) {
                    if (_address[i] === null) {
                        _address[i] = '';
                    }
                }
                if ('residenceDepartment' in address) {
                    self.setData({
                        type: 'residenceDepartment',
                        data: {residenceDepartment: address.residenceDepartment}
                    });
                }
                if (self.procedureType === CHANGEMENT_ADRESSE) {
                    self.person[oldAddress === true ? 'oldAddress' : 'newAddress'] = _address;
                } else {
                    self.person.address = _address;
                }
            }//
        };

        self.onBirthCountryChange = function () {
            let bc = self.person.birthCountry.trim();
            self.isFrance = bc.toLowerCase() === 'france';
        };

        self.onSIRENChange = function () {
            self.personForm.siren.$setValidity('siren', isValidSIRET(self.person.siren || ''));
        };

        self.removeSpaces = function (target, event) {
            event.preventDefault();
            let pastedData = (event.originalEvent || event).clipboardData.getData('text/plain');
            let newValue = pastedData.replace(/\s/g, '');

            console.log(event.target.dataset)

            if ('numericOnly' in event.target.dataset) {
                console.log('>>>> remove letters')
                newValue = newValue.replace(/[a-zA-Z]/g, '');
            }
            console.log('>>> newValue', newValue, 'error')
            if (event.target.maxLength > 0) {
                newValue = newValue.substr(0, event.target.maxLength);
            }
            self.person[target] = newValue;
        };

        self.next = function () {
            self.validate();
        };

        self.back = function () {
            $element[0].dispatchEvent(new CustomEvent('navigation', {
                detail: 'back'
            }));
        };

    }
});